import { Controller } from "@hotwired/stimulus";
import { validateFiles } from "./file_validation";

export default class extends Controller {
  static targets = ["file", "template", "submit", "list", "error", "wrapper", "button"];

  connect() {
    this.initDragAndDrop();
    this.addEventListeners();
    this.setConstraints();
    this.acceptedFormats = this.getAcceptedFormats();
    this.filesArray = [];
  }

  setConstraints() {
    const { dataset } = this.submitTarget;
    this.maxFiles = parseInt(dataset.maxFiles) || 15;
    this.maxTotalSize = parseInt(dataset.maxTotalSize) || null;
    this.maxFileSize = parseInt(dataset.maxFileSize) || 15 * 1024 * 1024;
    this.minWidth = parseInt(dataset.minWidth) || null;
    this.minHeight = parseInt(dataset.minHeight) || null;
  }

  getAcceptedFormats() {
    const accept = this.fileTarget.getAttribute("accept");
    return accept ? accept.split(",").map((format) => format.trim()) : [];
  }

  addEventListeners() {
    this.fileTarget.addEventListener("fileUploadError", this.handleErrorEvent.bind(this));
  }

  handleErrorEvent(event) {
    this.wrapperTarget.classList.add("error");
    this.errorTarget.textContent = event.detail.message;
  }

  initDragAndDrop() {
    const dragAndDropEvents = ["dragenter", "dragover", "dragleave", "drop"];
    const dragEvents = ["dragenter", "dragover"];
    const dropEvents = ["dragleave", "drop"];

    dragAndDropEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.preventDefaults.bind(this));
    });

    dragEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.highlight.bind(this));
    });

    dropEvents.forEach((eventName) => {
      this.wrapperTarget.addEventListener(eventName, this.unhighlight.bind(this));
    });

    this.wrapperTarget.addEventListener("drop", this.handleDrop.bind(this));
  }

  preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  highlight() {
    this.wrapperTarget.classList.add("dragover");
  }

  unhighlight() {
    this.wrapperTarget.classList.remove("dragover");
  }

  async handleDrop(event) {
    const dataTransferFiles = event.dataTransfer.files;
    this.fileTarget.files = dataTransferFiles;
    await this.updateFileList();
  }

  async updateFileList() {
    const newFiles = Array.from(this.fileTarget.files);

    this.filesArray = this.filesArray.concat(newFiles);

    const dataTransfer = new DataTransfer();
    this.filesArray.forEach((file) => dataTransfer.items.add(file));
    this.fileTarget.files = dataTransfer.files;

    this.listTarget.classList.remove("hidden");
    if (this.hasTemplateTarget) this.templateTarget.classList.remove("hidden");

    this.populateFileItem(this.fileTarget.files);

    if (this.hasTemplateTarget) this.templateTarget.classList.add("hidden");

    await this.validateFiles();
  }

  populateFileItem(files) {
    this.emptyFileList();

    Array.from(files).forEach((file) => {
      const clone = this.templateTarget.cloneNode(true);

      clone.classList.remove("file-item-template");
      clone.classList.add("file-item");
      clone.style.display = "flex";

      clone.querySelector(".file-name").textContent = file.name;
      clone.querySelector(".file-size").textContent = `${(file.size / 1024).toFixed(2)} KB`;

      this.listTarget.appendChild(clone);
    });
  }

  async validateFiles() {
    const isValid = await validateFiles(
      this.fileTarget.files,
      this.maxFiles,
      this.maxTotalSize,
      this.maxFileSize,
      this.acceptedFormats,
      this.minWidth,
      this.minHeight,
      this.fileTarget
    );

    if (isValid) {
      this.wrapperTarget.classList.remove("error");
      this.buttonTarget.disabled = false;
      this.allFilesPosted = false;
    }

    if (this.fileTarget.files.length === 0) {
      this.buttonTarget.disabled = true;
    }
  }

  emptyFileList() {
    const fileItems = Array.from(this.listTarget.children);
    const nonTemplateItems = fileItems.filter((item) => item.classList.contains("file-item"));

    nonTemplateItems.forEach((item) => item.remove());
  }

  async removeFile(event) {
    const button = event.target;
    const fileToRemove = button.closest(".file-item");

    const filesArray = Array.from(this.fileTarget.files);
    const fileIndexToRemove = filesArray.findIndex(
      (file) => file.name === this.getFileIdentifierToRemove(fileToRemove)
    );

    if (fileIndexToRemove > -1) {
      const dataTransfer = new DataTransfer();
      filesArray.forEach((file, index) => {
        if (index !== fileIndexToRemove) {
          dataTransfer.items.add(file);
        }
      });
      this.fileTarget.files = dataTransfer.files;
      this.filesArray = Array.from(dataTransfer.files);
    }

    fileToRemove.remove();

    await this.validateFiles();
  }

  getFileIdentifierToRemove(fileToRemove) {
    return fileToRemove.querySelector(".file-name").textContent;
  }
}
